import React, { useEffect } from "react";
import Leave1 from "../../Assets/springLeave1.png";
import Leave2 from "../../Assets/springLeave2.png";
import "./spring-animation.css";

const SpringAnimation = () => {
  useEffect(() => {
    const springDiv = document.getElementById("spring-animation");
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio || 1;
    ctx.scale(pixelRatio, pixelRatio);

    canvas.height = document.documentElement.clientHeight * pixelRatio;
    canvas.width = window.innerWidth * pixelRatio;

    canvas.style.height = `${document.documentElement.clientHeight}px`;
    canvas.style.width = `${window.innerWidth}px`;

    springDiv.appendChild(canvas);

    const w = canvas.width;
    const h = canvas.height;

    const leaves = [];
    const leafImages = [Leave1, Leave2];

    class springFall {
      static addLeaf() {
        if (leaves.length >= 35) return; // Limit the number of leaves
        const x = Math.ceil(Math.random() * w);
        const speed = Math.ceil(Math.random() * 3) + 1;
        const size = Math.ceil(Math.random() * 50) + 10;
        const img = new Image();
        img.src = leafImages[Math.floor(Math.random() * leafImages.length)];

        leaves.push({
          x: x,
          y: 0,
          speed: speed,
          size: size,
          img: img,
          rotation: Math.random() * 360,
        });
      }

      static fall() {
        ctx.clearRect(0, 0, w, h);
        for (let i = 0; i < leaves.length; i++) {
          const oneLeaf = leaves[i];

          ctx.save();
          ctx.translate(
            oneLeaf.x + oneLeaf.size / 2,
            oneLeaf.y + oneLeaf.size / 2
          );
          ctx.rotate((oneLeaf.rotation * Math.PI) / 180);
          ctx.drawImage(
            oneLeaf.img,
            -oneLeaf.size / 2,
            -oneLeaf.size / 2,
            oneLeaf.size,
            oneLeaf.size
          );
          ctx.restore();

          oneLeaf.y += oneLeaf.speed / 2; // Move leaf downward
          oneLeaf.rotation += oneLeaf.speed; // Rotate the leaf as it falls

          // Remove leaf if it goes out of bounds
          if (oneLeaf.y > h) {
            leaves.splice(i, 1);
            i--; // Adjust index after removal
          }
        }
      }

      static animate() {
        springFall.fall();
        // Generate a leaf with some probability on each animation frame
        if (Math.random() < 0.03) {
          // Adjust probability to generate a leaf
          springFall.addLeaf();
        }
        requestAnimationFrame(springFall.animate); // Use requestAnimationFrame for smooth animation
      }
    }

    // Start the animation
    springFall.animate();

    const handleResize = () => {
      canvas.height = document.documentElement.clientHeight * pixelRatio;
      canvas.width = window.innerWidth * pixelRatio;

      canvas.style.height = `${document.documentElement.clientHeight}px`;
      canvas.style.width = `${window.innerWidth}px`;
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      // Clean up leaves when component unmounts
      leaves.length = 0;
    };
  }, []);
  return <div className="spring-animation" id="spring-animation"></div>;
};

export default SpringAnimation;
