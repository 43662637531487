import React from "react";
import { CalculateSeasonDates } from "../../Utils/CalculateSeasonDates";
import SummerAnimation from "../SummerAnimation/SummerAnimation";
import SpringAnimation from "../SpringAnimation/SpringAnimation";
import AutumnAnimation from "../AutumnAnimation/AutumnAnimation";
import WinterAnimation from "../WinterAnimation/WinterAnimation";
import "./seasonal-animation.css";

const SeasonalAnimation = ({ currentDate }) => {
  const seasonDates = CalculateSeasonDates(currentDate.getFullYear());
  let animationComponent = null;
  const currentTime = currentDate.getTime();

  if (
    currentTime >= seasonDates.spring.start.getTime() &&
    currentTime <= seasonDates.spring.end.getTime()
  ) {
    animationComponent = <SpringAnimation />;
  } else if (
    currentTime >= seasonDates.summer.start.getTime() &&
    currentTime <= seasonDates.summer.end.getTime()
  ) {
    animationComponent = <SummerAnimation />;
  } else if (
    currentTime >= seasonDates.autumn.start.getTime() &&
    currentTime <= seasonDates.autumn.end.getTime()
  ) {
    animationComponent = <AutumnAnimation />;
  } else if (
    currentTime >= seasonDates.winter.start.getTime() &&
    currentTime <= seasonDates.winter.end.getTime()
  ) {
    animationComponent = <WinterAnimation />;
  }

  return <div className="seasonal-animation">{animationComponent}</div>;
};

export default SeasonalAnimation;
